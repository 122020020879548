import React from 'react'

export interface FullWContainerData {
  children: React.ReactNode
  className?: string
}

const FullWContainer = ({ children, className = '' }: FullWContainerData) => {
  return <div className={`w-full relative ${className}`}>{children}</div>
}

export default FullWContainer
