import React, { useState, useRef } from 'react'
import Arrow from '@/assets/Arrow'

interface AccordionData {
  heading: string
  children: any
  activeStyle: string
  className?: string
  richContent?: boolean
  buttonClass?: string
  useWysiStyles?: boolean
}

const Accordion = ({
  heading,
  children,
  activeStyle,
  className = '',
  richContent = false,
  buttonClass = '',
  useWysiStyles = true,
}: AccordionData) => {
  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')
  const [setRotate, setRotateState] = useState('arrow')

  const content: any = useRef(null)

  const toggle = (): void => {
    setActiveState(setActive === '' ? `active ${activeStyle}` : '')
    setHeightState(setActive === `active ${activeStyle}` ? '0px' : `${content.current.scrollHeight}px`)
    setRotateState(setActive === `active ${activeStyle}` ? 'arrow' : 'arrow rotate')
  }

  return (
    <>
      <div className={`${className} flex flex-col my-2  overflow-hidden rounded-bl-xl bg-primary`}>
        <button
          className={`p-5 accordion accordion-ani justify-between flex border-b-2 border-primary bg-white rounded-bl-xl border-l-2 ${setActive} ${buttonClass}`}
          onClick={toggle}
        >
          <h3 className="text-left tracking-wider text-xl">{heading}</h3>
          <span className={`${setRotate} accordion-ani arrow-icon`}>
            <Arrow />
          </span>
        </button>
        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className="overflow-auto accordion-ani px-8 bg-primary text-white rounded-bl-xl"
        >
          <div className={`my-7 ${useWysiStyles && 'wysiwyg'}`}>
            {richContent ? (
              <div className="" dangerouslySetInnerHTML={{ __html: children }} />
            ) : (
              <div className="">{children}</div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Accordion
