import React from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { useWindowSize } from '@/functions'

export interface Onview {
  children?: React.ReactNode
  offset?: number
  offsetMobile?: number
}

const variants = {
  show: { opacity: 1, y: 0 },
  hide: { opacity: 0, y: '50px' },
}

const Onview = ({ children, offset = 0.1, offsetMobile = 0 }: Onview) => {
  const width = useWindowSize()

  const aniOffset = width < 1024 ? offsetMobile : offset

  const { ref, inView } = useInView({
    threshold: aniOffset,
    triggerOnce: true,
  })

  return (
    <motion.div ref={ref} animate={inView ? 'show' : 'hide'} variants={variants} transition={{ duration: 0.7 }}>
      {children}
    </motion.div>
  )
}

export default Onview
