import React from 'react'
import RoundedLink from '@/components/RoundedLink'
import Image from '@/components/Image'
import { getImage } from 'gatsby-plugin-image'

interface TextAndImageData {
  link?: string
  alt?: string
  className?: string
  linkText?: string
  content?: any
  img?: any
  imgSide?: string
  children: any
  heading?: string
  useh1?: boolean
  intro?: string
}

const TextAndImage = ({
  link,
  alt,
  className = '',
  linkText,
  heading,
  children,
  img,
  intro,
  imgSide,
  useh1 = false,
}: TextAndImageData) => {
  const image: any = getImage(img?.localFile) || img || null

  return (
    <div className={`${className} text-and-image w-full grid grid-cols-10`}>
      {img && imgSide == '1' && (
        <div className="col-span-10 sm:col-span-5 flex sm:block justify-center">
          <div className="sm:mr-14 mb-8 sm:mb-0">
            <Image image={image} className={'h-auto relative w-full'} alt={alt ? alt : 'Kuva / Image'} />
          </div>
        </div>
      )}

      <div className="col-span-10 sm:col-span-5 flex flex-col justify-center items-start break-words">
        {heading &&
          (useh1 ? (
            <h1 className="mb-4 w-full break-words">{heading}</h1>
          ) : (
            <h2 className="mb-4 w-full break-words">{heading}</h2>
          ))}
        <div className={``}>
          {intro && <p className="font-bold text-2xl mb-7">{intro}</p>}
          {children && <div className="mb-10" dangerouslySetInnerHTML={{ __html: children }} />}
          {link && (
            <RoundedLink className="" to={link}>
              {linkText}
            </RoundedLink>
          )}
        </div>
      </div>

      {img && (imgSide == '2' || imgSide == '') && (
        <div className="col-span-10 sm:col-span-5 flex sm:block justify-center">
          <div className="sm:ml-14 mt-8 sm:mt-0">
            <Image image={image} className={'h-auto relative w-full'} alt={alt ? alt : 'Kuva / Image'} />
          </div>
        </div>
      )}
    </div>
  )
}

export default TextAndImage
