import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import RoundedLink from '@/components/RoundedLink'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import { quotations } from '@/types.d'

export interface QuotationsData {
  slides: Array<quotations>
  buttonText?: string
  buttonLink?: string
}

const Quotations = ({ slides, buttonText, buttonLink }: QuotationsData) => {
  if (slides == []) {
    return null
  }

  return (
    <div className="my-14 md:mx-10 lg:mx-20">
      <div>
        <Swiper
          className="pb-[30px!important] asdasdsdadsad"
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {slides.map((slide, index: number) => {
            return (
              <SwiperSlide
                key={index}
                className="bg-primary text-white rounded-full min-h-[250px] flex items-center text-center justify-center p-14 flex-col font-bold text-2xl"
              >
                <p>{slide.heading}</p>
                {slide.content && <p className="text-xl mt-4">{`– ${slide.content}`}</p>}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      <div className="text-center flex flex-col items-center">
        {buttonLink && (
          <RoundedLink className="text-white bg-primary mt-6" to={buttonLink}>
            {buttonText}
          </RoundedLink>
        )}
      </div>
    </div>
  )
}

export default Quotations
