import React from 'react'
import Image from '@/components/Image'
import { getImage } from 'gatsby-plugin-image'

interface DecorativeImageData {
  img: any
  alt: string
  className?: string
}

const DecorativeImage = ({ img, alt, className = '' }: DecorativeImageData) => {
  const image: any = getImage(img?.localFile) || null

  if (image == null) {
    return null
  }

  return (
    <div className={`hidden md:block decorative-image absolute -top-20 right-0 ${className}`}>
      {image && (
        <Image
          image={image}
          className={'h-auto relative w-full'}
          alt={alt ? alt : 'Koristeellinen kuva / decorative image'}
        />
      )}
    </div>
  )
}

export default DecorativeImage
