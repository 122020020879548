import React, { useEffect } from 'react'
import TextAndImage from '@/components/TextAndImage'
import Layout from '@/components/Layout'
import Container from '@/components/Container'
import Accordion from '@/components/Accordion'
import { graphql } from 'gatsby'
import Quotations from '@/components/Quotations'
import FullWContainer from '@/components/FullWContainer'
import DecorativeImage from '@/components/DecorativeImage'
import { seo, pagelang, quotations } from '@/types.d'
import Onview from '@/components/Onview'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface SingleTasteData {
  data: {
    taste: {
      title: string
      featuredImage: any
      intro: string
      uri: string
      content: any
      Ingredients: any
      Energy: string
      Fatt: string
      SatisfyingFatt: string
      Carbohydrate: string
      Sugar: string
      Protein: string
      Salt: string
      seo: seo
      TasteInfoRepeater: any
      Quotations: Array<quotations>
      language: pagelang
      translations: any
      accodionImage: any
    }
    mainMenu: any
    langData: any
    instagram: any
    wp: any
    form: any
  }
}

const SingleTasteTemplate = ({ data }: SingleTasteData) => {
  const { taste, mainMenu, instagram, wp, form } = data

  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(taste?.language?.slug)
  }, [])

  mainMenu.navButton = wp?.navButtons || ''
  const langData = { languages: wp.languages, currentLang: taste.language.slug, translations: taste.translations }

  const tastesPage =
    taste.language.slug === 'fi'
      ? wp.archiveButton.archiveFi
      : taste.language.slug === 'en'
      ? wp.archiveButton.archiveEn
      : wp.archiveButton.archiveFi

  const tastebutton = tastesPage ? JSON.parse(tastesPage) : ''

  const nutritions = [
    { title: t('Energia'), amount: taste?.Energy, unit: 'kcal', ischild: false },
    { title: t('Rasva'), amount: taste?.Fatt, unit: 'g', ischild: false },
    { title: t('Tyyd'), amount: taste?.SatisfyingFatt, unit: 'g', ischild: true },
    { title: t('Hiilihydraatit'), amount: taste?.Carbohydrate, unit: 'g', ischild: false },
    { title: t('Sokeri'), amount: taste?.Sugar, unit: 'g', ischild: true },
    { title: t('Proteiini'), amount: taste?.Protein, unit: 'g', ischild: false },
    { title: t('Suola'), amount: taste?.Salt, unit: 'g', ischild: false },
  ]

  return (
    <Layout
      form={form}
      showhero={false}
      nav={mainMenu}
      lang={langData}
      instagram={instagram}
      featuredImage={taste?.featuredImage?.node?.localFile?.url}
      uri={taste?.uri}
      seodesc={taste?.seo?.metaDesc}
      seokeywors={taste?.seo?.metaKeywords}
      seotitle={taste?.seo?.title}
    >
      <Container className="mt-24 sm:mt-28 md:mt-36 xl:mt-48 mb-20 md:mb-28">
        <TextAndImage
          heading={taste?.title}
          alt={taste?.featuredImage?.node?.altText}
          link={tastebutton?.url}
          linkText={tastebutton?.anchor}
          img={taste?.featuredImage?.node}
          imgSide="1"
          useh1={true}
          intro={taste?.intro}
        >
          {taste?.content}
        </TextAndImage>
      </Container>
      {taste?.Quotations?.length > 0 && (
        <Container className="my-20 md:my-28">
          <Quotations slides={taste?.Quotations} buttonLink={tastesPage?.url} buttonText={tastesPage?.anchor} />
        </Container>
      )}
      <FullWContainer>
        <Container className="my-20 md:my-32">
          <Onview>
            <div className="w-full md:w-4/6">
              <Accordion heading={t('Ainesosat')} activeStyle="text-primary" richContent={true}>
                {taste?.Ingredients && taste.Ingredients}
              </Accordion>
              <Accordion
                heading={t('Ravintosisältö')}
                activeStyle="text-primary"
                className=""
                richContent={false}
                useWysiStyles={false}
              >
                <table className="w-full mb-10">
                  <tbody>
                    {nutritions.map((item, index: number) => {
                      return (
                        <tr className="border-b border-white" key={index}>
                          <td className={`py-1 ${item.ischild && 'pl-6'}`}>{item.title}</td>
                          <td className="py-1">
                            {item.amount || ''} {item.unit || ''}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Accordion>
              {taste?.TasteInfoRepeater &&
                taste.TasteInfoRepeater.map((info: any, index: number) => {
                  return (
                    <Accordion
                      key={index}
                      heading={info.heading}
                      activeStyle="text-primary"
                      className=""
                      richContent={true}
                    >
                      {info.content}
                    </Accordion>
                  )
                })}
            </div>
          </Onview>
        </Container>
        <DecorativeImage img={taste?.accodionImage} alt={taste?.accodionImage?.altText} className="" />
      </FullWContainer>
    </Layout>
  )
}

export default SingleTasteTemplate

export const singleTasteQuery = graphql`
  query TasteById($id: String!, $menu: [WpMenuLocationEnum], $translang: String, $footerForm: Int) {
    taste: wpGwTaste(id: { eq: $id }) {
      ...featuredImageTaste
      ...singleTasteFields
      ...langdataTaste
      seo {
        metaDesc
        metaKeywords
        title
      }
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      ...subscribe
      ...archivePage
    }
    instagram: allInstagramContent(limit: 5) {
      ...instagram
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $footerForm }) {
      ...GravityFormFields
    }
  }
`
